import "./Ris.css"
import {ReactNode} from "react";
import {LineItem} from "./functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFire} from "@fortawesome/pro-solid-svg-icons/faFire";
import {faSnowflake} from "@fortawesome/pro-solid-svg-icons/faSnowflake";
import {faArrowAltLeft} from "@fortawesome/pro-solid-svg-icons/faArrowAltLeft";
import {faArrowAltUp} from "@fortawesome/pro-solid-svg-icons/faArrowAltUp";
import {faArrowAltRight} from "@fortawesome/pro-solid-svg-icons/faArrowAltRight";
import {faSignal} from "@fortawesome/pro-solid-svg-icons/faSignal";
import {faBroadcastTower} from "@fortawesome/pro-solid-svg-icons/faBroadcastTower";
import {faCompress} from "@fortawesome/pro-solid-svg-icons/faCompress";

type RisProps = {
    line: ReactNode,
    course: ReactNode
    departureAt: string
    tick: number
    stops: LineItem[]
}

export const Ris = (props: RisProps) => {
    const { line, course, stops, departureAt, tick } = props
    const foundStop = stops.findIndex((stop) => {
        return stop.departure && stop.departure > tick;
    })
    const activeStop = foundStop === -1 ? stops.length-1 : foundStop
    const terminus = stops[stops.length - 1]
    const prevStop = activeStop > 0 ? stops[activeStop - 1] : null
    const stop = stops[activeStop]
    const nextStop = activeStop+1 < stops.length ? stops[activeStop + 1] : null
    const nextNextStop = activeStop+1 < stops.length ? stops[activeStop + 2] : null
    const nextNextNextStop = activeStop+1 < stops.length ? stops[activeStop + 3] : null
    const nextNextNextNextStop = activeStop+1 < stops.length ? stops[activeStop + 4] : null
    const nextNextNextNextNextStop = activeStop+1 < stops.length ? stops[activeStop + 5] : null
    const departureTime = new Date(`1970-01-01 ${departureAt}`)
    const actualTime = new Date(departureTime.getTime() + tick * 1000)

    const isStationing = stop.arrival && stop.departure && stop.arrival < tick && stop.departure > tick
    const isBeforeScheduledDeparture = stop.time !== null && stop.time !== undefined && stop.time > tick

    const getTime = (seconds: number | null | undefined) => {
        if(seconds === null || seconds === undefined) {
            return ""
        }
        const time = new Date(new Date(`1970-01-01 ${departureAt}`).getTime() + seconds * 1000)
        return `${time.getHours()}:${time.getMinutes().toString().padStart(2, "0")}`
    }

    const offsetFromScheduledDeparture = stop.time  * 1000 - tick * 1000
    const timeToDeparture = new Date(new Date(`1970-01-01 0:00:00`).getTime() + Math.abs(offsetFromScheduledDeparture))

    return (
        <div id="ris2">
            <div className="header">
                <div className="line">L:{line}</div>
                <div className="course">K:{course}</div>
                <div className="zone">Z:<span className="js-inject-zone">{stop.zone}</span></div>
                <div className="time js-inject-time">{actualTime.getHours()}:{actualTime.getMinutes().toString().padStart(2, "0")}:{actualTime.getSeconds().toString().padStart(2, "0")}</div>
            </div>
            <div className="stops-container">
                <div className="previous-stop">
                    <div className="time js-inject-previous-departure-time">{getTime(prevStop?.time)}</div>
                    <div className="stop js-inject-previous-stop">{prevStop?.stop}</div>
                </div>
                <div className={`next-stop ${isStationing ? isBeforeScheduledDeparture ? "background-red" : "background-green" : undefined}`}>
                    <div className="time js-inject-departure">{getTime(stop.time)}</div>
                    <div className="stop js-inject-stop">{stop.stop}</div>
                    <div className="time-to-departure js-inject-delay">{offsetFromScheduledDeparture > 0 ? "-" : ""}{timeToDeparture.getMinutes()}:{timeToDeparture.getSeconds().toString().padStart(2, "0")}</div>
                </div>
                <div className="future-stop future-stop-1st">
                    <div className="time js-inject-next-departure-time-1">{getTime(nextStop?.time)}</div>
                    <div className="stop js-inject-next-stop-1">{nextStop?.stop}</div>
                </div>
                <div className="future-stop future-stop-2nd">
                    <div className="time js-inject-next-departure-time-2">{getTime(nextNextStop?.time)}</div>
                    <div className="stop js-inject-next-stop-2">{nextNextStop?.stop}</div>
                </div>
                <div className="future-stop future-stop-3rd">
                    <div className="time js-inject-next-departure-time-3">{getTime(nextNextNextStop?.time)}</div>
                    <div className="stop js-inject-next-stop-3">{nextNextNextStop?.stop}</div>
                </div>
                <div className="future-stop future-stop-4th">
                    <div className="time js-inject-next-departure-time-4">{getTime(nextNextNextNextStop?.time)}</div>
                    <div className="stop js-inject-next-stop-4">{nextNextNextNextStop?.stop}</div>
                </div>
                <div className="future-stop future-stop-5th">
                    <div className="time js-inject-next-departure-time-5">{getTime(nextNextNextNextNextStop?.time)}</div>
                    <div className="stop js-inject-next-stop-5">{nextNextNextNextNextStop?.stop}</div>
                </div>
                <div className="horizontal-row"></div>
                <div className="future-stop terminus-stop">
                    <div className="time js-inject-terminus-departure-time">{getTime(terminus.time)}</div>
                    <div className="stop js-inject-terminus-stop">{terminus.stop}</div>
                </div>
                <div className="terminus-button">
                    <div className="line">{line}</div>
                    <div className="terminus js-inject-terminus-stop">{terminus.stop}</div>
                </div>
            </div>
            <div className="icons-container">
                <div className="icon icon1"></div>
                <div className="icon icon2"></div>
                <div className="icon icon3"></div>
                <div className="icon icon4"></div>
                <div className="icon icon5"></div>
            </div>
            <div className="footer">
                <div className="item no-margin-left">
                    <p>
                        <FontAwesomeIcon icon={faCompress}  transform={{ rotate: 45 }} />
                    </p>
                </div>

                <div className="js-inject-switches">
                    <div className="item">
                        <p>
                            <FontAwesomeIcon icon={faArrowAltLeft} />
                            <FontAwesomeIcon icon={faArrowAltUp} />
                            <FontAwesomeIcon icon={faArrowAltRight} />
                        </p>
                    </div>
                </div>

                <div className="item red float-right no-margin-right">
                    <p>
                        <FontAwesomeIcon icon={faFire} />
                    </p>
                </div>
                <div className="item red float-right">
                    <p>
                        <FontAwesomeIcon icon={faSnowflake} />
                    </p>
                </div>
                <div className="item green float-right">
                    <p>
                        OZN
                    </p>
                </div>
                <div className="item green float-right">
                    <p>
                        GPS
                    </p>
                </div>
                <div className="item green float-right">
                    <p>
                        PERIF
                    </p>
                </div>
                <div className="item green float-right">
                    <p>
                        LTE<FontAwesomeIcon icon={faSignal} />
                    </p>
                </div>
                <div className="item blue float-right">
                    <p>
                        <FontAwesomeIcon icon={faBroadcastTower} /><FontAwesomeIcon icon={faSignal} />
                    </p>
                </div>
            </div>
        </div>
    )
}
