import React, {useState} from 'react';
import {Ris} from "./Ris";
import {Box, Button, Group, MantineProvider, Slider, Textarea, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {generate} from "./functions";
import {useInterval} from "@mantine/hooks";

const time = {
    0: 1000,
    1: 100,
    2: 10,
}

function App() {
    const [seconds, setSeconds] = useState(-60);
    const [speed, setSpeed] = useState<0 | 1 | 2>(0);
    const interval = useInterval(() => setSeconds((s) => s + 1), time[speed]);
    const form = useForm({
        initialValues: {
            line: "1",
            course: "00101",
            departureFromTerminus: "16:39",
            plan: "zastavka,zona,prijezd,odjezd,jr,vyhybky,vozicek,znameni\n" +
                "Komárov,101,0:02,3:17,16:39:00,,1,\n" +
                "Konopná,100,4:01,4:27,0:00,,1,\n" +
                "Železniční,100,5:09,5:26,1:00,,1,Z\n" +
                "Autobusové nádraží,100,6:06,7:05,2:00,,1,\n" +
                "Úzká,100,8:27,8:44,4:00,,1,\n" +
                "Hlavní nádraží,100,11:29,12:12,7:00,,1,\n" +
                "Nové sady,100,13:28,14:17,8:00,,1,\n" +
                "Šilingrovo náměstí,100,16:43,17:24,10:00,,1,\n" +
                "Česká,100,20:40,21:04,13:00,,1,\n" +
                "Grohova,100,22:33,22:57,14:00,,1,\n" +
                "Konečného náměstí,100,23:45,24:09,16:00,,1,\n" +
                "Nerudova,100,25:56,27:02,17:00,,1,\n" +
                "Klusáčkova,100,29:35,29:55,19:00,,1,\n" +
                "Tererova,100,31:24,31:42,20:00,,1,\n" +
                "Dobrovského,100,32:09,32:34,21:00,,1,\n" +
                "Skácelova,101,34:06,34:31,23:00,,1,\n" +
                "Červinkova,101,35:28,35:44,24:00,,1,\n" +
                "Technické muzeum,101,36:22,36:35,25:00,,1,\n" +
                "Technologický park,101,37:32,-,28:00,,1,\n"
        }
    })


    const stops = generate(form.values.plan)

  return (
      <MantineProvider withGlobalStyles withNormalizeCSS>
          <Group align={"start"}>
          <Box m={24} sx={{
              width:"60vw",
          }}>
        <Ris line={form.values.line} course={form.values.course} stops={stops} departureAt={form.values.departureFromTerminus} tick={seconds} />
          </Box>
              <Box>
                  <Button onClick={interval.toggle}>Play/Stop</Button>
                  <Slider
                      py={24}
                      marks={[
                          { value: 0, label: '1s' },
                          { value: 1, label: '100ms' },
                          { value: 2, label: '10ms' },
                      ]}
                      min={0}
                      max={2}
    onChange={(value) => setSpeed(value as 0 | 1 | 2)}
                  />
                  <TextInput label="Line" {...form.getInputProps('line')} />
                  <TextInput label="Course" {...form.getInputProps('course')} />
                    <TextInput label="Departure from terminus" {...form.getInputProps('departureFromTerminus')} />
<Textarea label="Plan" {...form.getInputProps('plan')} />
              </Box>
          </Group>
      </MantineProvider>
  );
}

export default App;
