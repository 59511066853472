export type LineItem = {
    stop: string;
    zone: string;
    arrival: number | null;
    departure: number | null;
    time: number;
    switches: string;
    onRequest: boolean;
    wheelchairAccessible: number;
    departureTime: string | null;
}

export function generate(csvData: string) {
    const COL_ZASTAVKA = "zastavka";
    const COL_ZONA = "zona";
    const COL_PRIJEZD = "prijezd";
    const COL_ODJEZD = "odjezd";
    const COL_JR = "jr";
    const COL_VYHYBKY = "vyhybky";
    const COL_VOZICEK = "vozicek";
    const COL_ZNAMENI = "znameni";
    let row: string[] = [];
    let keys: Record<string, number> = {};
    let list: LineItem[] = [];
    let switches = "N";
    let i = 0;
    const handle = csvData.split("\n");
    handle.forEach((line) => {
        const data = line.split(",");
        if (i === 0) {
            keys = getKeys(data);
            i++;
            return;
        }
        if (data[0] === "") {
            i++;
            return;
        }
        loadRow(data);
        const item:LineItem = {
            stop: getValue(COL_ZASTAVKA) ?? "",
            zone: getValue(COL_ZONA) ?? "",
            arrival: getSecondsFromTime(getValue(COL_PRIJEZD) as string) as unknown as number,
            departure: getSecondsFromTime(getValue(COL_ODJEZD) as string) as unknown as number,
            time: getSecondsFromTime(getValue(COL_JR) as string) || 0,
            switches,
            onRequest: !!getValue(COL_ZNAMENI),
            wheelchairAccessible: getValue(COL_VOZICEK) as unknown as number || 0,
            departureTime: null,
        };
        if (getSecondsFromTime(data[4]) === null) {
            item.departureTime = data[4];
        }
        switches = getValue(COL_VYHYBKY) || "N";
        list.push(item);
        i++;
    });

    return list;

    function getSecondsFromTime(time: string) {
        if (/^\d{1,2}:\d{2}$/.test(time)) {
            const data = new Date(`1970-01-01 0:${time}`);
            return data.getMinutes() * 60 + data.getSeconds();
        }
    }

    function loadRow(rowData: string[]) {
        row = rowData;
    }

    function getValue(item: string) {
        if (row[keys[item]] !== undefined) {
            return row[keys[item]];
        }
        return null;
    }

    function getKeys(rowData: string[]) {
        const list: Record<string, number> = {};
        rowData.forEach((value, key) => {
            list[value.toLowerCase()] = key;
        });
        return list;
    }
}